<template>
  <section class="about-section -section-six">
    <div class="card card-bg-sand pa-6 pa-md-10 flex-grow-1">
      <div class="icon-block">
        <BaseSpriteIcon icon-name="ico-ai-24-outlined" />
      </div>
      <h2 class="title-block">
        Start reading <br />
        20 minutes a day
      </h2>

      <p class="section-bottom-text">
        Reading regularly for just 20 minutes a day can help you learn new
        words, improve grammar and pronunciation naturally, and feel more
        confident in English.
      </p>

      <TrialButton class="mt-6" />
    </div>
  </section>
  <!--section-->
</template>

<script>
import TrialButton from './TrialButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  components: {
    TrialButton,
    BaseSpriteIcon
  }
};
</script>

<style lang="less">
@import url('./AboutViewMedia.less');

.about-section {
  &.-section-six {
    .icon-block {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;

      svg {
        width: 64px;
        height: 64px;

        @media @narrow, @normal {
          width: 56px;
          height: 56px;
        }

        @media @small {
          width: 40px;
          height: 40px;
        }
      }
    }

    .card {
      gap: 0;

      > button {
        background-color: #0f172a;
        color: #fff;
      }
    }

    .v-btn__content {
      display: flex;
      gap: calc(var(--gap) / 3 * 2);

      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }
    }

    .section-bottom-text {
      margin-top: 8px;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.02em;
      text-align: center;
      max-width: 480px;

      @media @small {
        font-size: 16px;
        line-height: 24px;
        max-width: 420px;
      }
    }
  }
}
</style>
