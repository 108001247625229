<template>
  <section class="about-section -section-two">
    <div class="card card-bg-sand pa-6 pa-md-10 flex-grow-1">
      <h2 class="title-block">How does immersive reading work?</h2>

      <BookPreview></BookPreview>

      <BaseButton
        v-if="isOnline"
        class="align-self-center mt-6"
        x-large
        elevation="0"
        @click="playBookFragment"
        ><BaseSpriteIcon icon-name="ico-book-info-read-solid" />Play
        example</BaseButton
      >

      <p v-if="isOnline" class="section-bottom-text">
        Make sure that the sound is on
      </p>

      <TrialButton class="-for-guest mt-12" style="display: none;" />
    </div>
  </section>
  <!--section-->
</template>

<script>
import { mapGetters } from 'vuex';
import PublicationLogicService from '@/services/PublicationLogic/PublicationLogicService';

import TrialButton from './TrialButton.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BookPreview from '@/components/views/BookPreview/BookPreview.vue';

export default {
  components: {
    BookPreview,
    TrialButton,
    BaseButton,
    BaseSpriteIcon
  },
  computed: {
    ...mapGetters('ContextStore', ['isOnline'])
  },
  methods: {
    isAudioPlaying() {
      return this.$store.getters['PlaybackStore/isAudioPlaying'];
    },
    async playBookFragment() {
      if (this.isAudioPlaying()) {
        this.$store.dispatch('PlaybackStore/pause');
      }
      await PublicationLogicService.immediatePlayAudioFromStartRange(
        this.$store,
        this.$router
      );
    }
  }
};
</script>

<style lang="less">
.about-section {
  &.-section-two {
    .card {
      gap: 0;

      > button {
        background-color: #0f172a;
        color: #fff;
      }
    }

    .v-btn__content {
      display: flex;
      gap: calc(var(--gap) / 3 * 2);

      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }
    }

    .section-bottom-text {
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      font-weight: 500;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }
  }
}
</style>
