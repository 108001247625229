<template>
  <section class="language-app -section-four">
    <h3 class="narrow-header">
      A simple way to learn English and grow your vocabulary
    </h3>

    <div v-if="!isSmallScreen" class="slider-container">
      <div class="slider-navigation">
        <h3 class="wide-header">
          A simple way to learn English and grow your vocabulary
        </h3>
        <div class="nav-items">
          <div
            v-for="(slide, index) in slides"
            :key="slide.id"
            class="nav-item"
            :class="{ active: currentIndex === index }"
            @click="goToSlide(index)"
          >
            <div
              class="nav-indicator"
              :class="{ 'active-indicator': currentIndex === index }"
            ></div>
            <div class="nav-content">
              <h3>{{ slide.title }}</h3>
              <p>{{ slide.description }}</p>

              <BaseButton
                v-if="slide.buttonText"
                elevation="0"
                text
                color="#ffd54f"
                @click="toTest"
              >
                <span>{{ slide.buttonText }}</span>
                <BaseSpriteIcon
                  icon-name="ico-arrow-right-line"
                ></BaseSpriteIcon>
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <transition-group name="fade" tag="div" class="slides">
        <!-- Slide 1: Reading Level Test -->
        <div v-if="currentIndex === 0" key="slide-1" class="slide test-slide">
          <div class="slide-pic">
            <v-img :lazy-src="picSlider1" :src="picSlider1" alt="picSlider1" />
          </div>
        </div>

        <!-- Slide 2: Progress Tracker -->
        <div
          v-if="currentIndex === 1"
          key="slide-2"
          class="slide tracker-slide"
        >
          <div class="slide-pic">
            <v-img :lazy-src="picSlider2" :src="picSlider2" alt="picSlider2" />
          </div>
        </div>

        <!-- Slide 3: Reading Book -->
        <div
          v-if="currentIndex === 2"
          key="slide-3"
          class="slide reading-slide"
        >
          <div class="slide-pic">
            <v-img :lazy-src="picSlider3" :src="picSlider3" alt="picSlider3" />
          </div>
          <div class="slide-pic2">
            <v-img
              :lazy-src="picSlider3a"
              :src="picSlider3a"
              alt="picSlider3a"
            />
          </div>
        </div>

        <!-- Slide 4: Word Explorer -->
        <div
          v-if="currentIndex === 3"
          key="slide-4"
          class="slide word-explorer-slide"
        >
          <div class="slide-pic">
            <v-img :lazy-src="picSlider4" :src="picSlider4" alt="picSlider4" />
          </div>
        </div>
      </transition-group>
    </div>
    <div v-else class="slider-list">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="slider-list-item"
      >
        <div class="pic-bg" :class="slide.selector">
          <div class="slide-pic">
            <v-img :lazy-src="slide.img" :src="slide.img" :alt="slide.title" />
          </div>
          <div v-if="slide.img2" class="slide-pic2">
            <v-img
              :lazy-src="slide.img2"
              :src="slide.img2"
              :alt="slide.title"
            />
          </div>
        </div>
        <!-- /pic-bg -->
        <div class="text-block">
          <h3>{{ slide.title }}</h3>
          <p>{{ slide.description }}</p>
          <BaseButton
            v-if="slide.buttonText"
            elevation="0"
            text
            color="#ffd54f"
            @click="toTest"
          >
            <span>{{ slide.buttonText }}</span>
            <BaseSpriteIcon icon-name="ico-arrow-right-line"></BaseSpriteIcon>
          </BaseButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

import AppStateEnum from '@/enums/AppStateEnum';

import picSlider1 from '@/components/views/AboutView/ffa/img/pic-slider-1.webp';
import picSlider2 from '@/components/views/AboutView/ffa/img/pic-slider-2.webp';
import picSlider3 from '@/components/views/AboutView/ffa/img/dummy-book.webp';
import picSlider3a from '@/components/views/AboutView/ffa/img/phone-pic.webp';
import picSlider4 from '@/components/views/AboutView/ffa/img/pic-slider-4.webp';

export default {
  name: 'LanguageAppSlider',
  components: {
    BaseButton,
    BaseSpriteIcon
  },
  data() {
    return {
      currentIndex: 0,
      timer: null,
      picSlider1,
      picSlider2,
      picSlider3,
      picSlider3a,
      picSlider4,
      slides: [
        {
          id: 1,
          selector: 'test-slide',
          title: 'Discover your reading level',
          description:
            'Take a short test to see your vocabulary level and find the right books',
          buttonText: 'Start online test',
          img: picSlider1
        },
        {
          id: 2,
          selector: 'tracker-slide',
          title: 'Set goal and track your progress',
          description: 'Start with 20 minutes a day and see amazing progress',
          img: picSlider2
        },
        {
          id: 3,
          selector: 'reading-slide',
          title: 'Read every day',
          description:
            'Hear the correct pronunciation and understand the words better while you read along',
          img: picSlider3,
          img2: picSlider3a
        },
        {
          id: 4,
          selector: 'word-explorer-slide',
          title: 'Explore unknown words',
          description:
            'Tap on unknown words to see their meaning and translation to your language right away',
          img: picSlider4
        }
      ],
      autoplayInterval: 5000
    };
  },
  computed: {
    isSmallScreen() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    }
  },
  mounted() {
    this.startAutoplay();
  },
  beforeDestroy() {
    this.stopAutoplay();
  },
  methods: {
    startAutoplay() {
      this.timer = setInterval(() => {
        this.nextSlide();
      }, this.autoplayInterval);
    },
    stopAutoplay() {
      clearInterval(this.timer);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },
    goToSlide(index) {
      this.stopAutoplay();
      this.currentIndex = index;
      this.startAutoplay();
    },
    toTest() {
      this.$router.push({ name: AppStateEnum.ASSESSMENT });
    }
  }
};
</script>

<style lang="less">
@import url('./AboutViewMedia.less');

.language-app {
  background-color: #2b3b6b;
  color: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 56px;

  @media @narrow {
    padding: 24px;
  }

  @media @small {
    padding: 16px;
  }

  .wide-header {
    @media @small, @narrow {
      display: none;
    }
  }

  .narrow-header {
    display: none;
    margin-bottom: 0.5em;

    @media @small, @narrow {
      display: block;
    }
  }

  h3 {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0.04em;
  }

  .slider-container {
    display: flex;
    position: relative;
    min-height: 600px;
    gap: 16px;

    @media @small, @narrow {
      flex-direction: column-reverse;
      min-height: 0;
    }

    .slides {
      flex: 3;
      position: relative;
      padding: 20px;
      overflow: hidden;
      border-radius: 16px;

      @media @small, @narrow {
        min-height: 100dvw;
      }

      .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .slider-navigation {
      flex: 2;
      min-width: 480px;

      @media @small, @narrow {
        min-width: 0;
      }

      .nav-items {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .nav-item {
          display: flex;
          cursor: pointer;
          transition: transform 0.3s;
          gap: 16px;

          &:hover {
            transform: translateX(5px);
          }

          .nav-indicator {
            width: 8px;
            background-color: #4d5d8c;
            border-radius: 4px;
            flex-shrink: 0;
          }

          .active-indicator {
            background-color: #ffd54f;
          }

          .nav-content {
            h3 {
              margin: 0 0 8px 0;
              font-size: 20px;
              font-weight: 600;
            }

            p {
              margin: 0 0 12px 0;
              font-size: 16px;
              opacity: 0.85;
              line-height: 1.5;
            }

            .nav-button {
              color: #ffd54f;
              font-weight: 600;
              text-decoration: none;
              display: inline-flex;
              align-items: center;
              font-size: 16px;

              .arrow-icon {
                margin-inline-start: 8px;
              }
            }

            button {
              padding: 0 !important;
              height: auto !important;

              &:before {
                display: none;
              }

              svg {
                margin-top: 2px;
                margin-inline-start: 0.5em;
              }
            }
          }
        }
      }
    }
  }

  .slider-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .slider-list-item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      font-size: 18px;
      line-height: 26px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.7);
    }

    a {
      color: #ffd54f;
      font-weight: 600;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
    }

    .arrow-icon {
      margin-inline-start: 8px;
    }

    button {
      padding: 0 !important;
      height: auto !important;

      &:before {
        display: none;
      }

      svg {
        margin-top: 2px;
        margin-inline-start: 0.5em;
      }
    }
  }

  .pic-bg {
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border-radius: 16px;
  }

  .slide-pic {
    width: 70%;

    @media @small, @narrow {
      width: 90%;
    }
  }

  // Test Slide Styles
  .test-slide {
    display: flex;
    background-color: #c8d1e7;
  }

  // Progress Tracker Styles
  .tracker-slide {
    display: flex;
    background-color: #c4eeff;
  }

  // Reading Book Styles
  .reading-slide {
    display: flex;
    background-color: #ffe3fa;
    position: relative;
    align-items: flex-end !important;

    &.pic-bg {
      padding: 40px 0 0;
    }

    .slide-pic {
      width: 36%;
      position: absolute;
      bottom: 3%;
      border-radius: 30px;
      overflow: hidden;

      @media @narrow {
        width: 35%;
        bottom: 5%;
      }
    }

    .slide-pic2 {
      width: 61%;
      position: relative;
      margin-inline-start: 13%;
    }
  }

  // Word Explorer Styles
  .word-explorer-slide {
    display: flex;
    background-color: #fad873;
  }

  // Animation
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
