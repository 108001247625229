<template>
  <section class="about-section -section-eight section-carousel">
    <div class="section-side flex-grow-1">
      <div class="card card-small card-bg-gray pa-6 pa-lg-8 flex-grow-1">
        <div class="pre-text">Testimonials</div>
        <h2 class="card-title">What users say<br />about WholeReader</h2>
        <div class="carousel-quote-img">
          <v-img :src="carouselQuote" alt="carousel-pic" />
        </div>
        <v-carousel v-model="carouseModel" continuous hide-delimiters>
          <template #prev="{ on, attrs }">
            <BaseButton
              v-bind="attrs"
              color="#0F172A"
              fab
              small
              elevation="0"
              v-on="on"
              ><BaseSpriteIcon :icon-name="'ico-arrow-front-long'"
            /></BaseButton>
          </template>
          <template #next="{ on, attrs }">
            <BaseButton
              v-bind="attrs"
              color="#0F172A"
              fab
              small
              elevation="0"
              v-on="on"
              ><BaseSpriteIcon :icon-name="'ico-arrow-front-long'"
            /></BaseButton>
          </template>
          <v-carousel-item>
            <div class="carousel-item">
              <div class="carousel-text">
                With WholeReader, you don't just read a book, you listen to it
                too and it helps you improve your pronunciation. I am learning
                many new words every day, which I would never have been able to
                do while learning English at school.
              </div>
              <div class="carousel-sign">Mino <span>Madagascar</span></div>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="carousel-item">
              <div class="carousel-text">
                I trained my Iraqi student, a mother with reasonable English to
                help her 8 year old boy who had difficulty in English class in
                school, to use WholeReader stories as an extracurricular
                activity to improve his reading. After two months of reading
                every day for one hour with his mother. He was able to catch up
                with his classmates. The school teacher was very surprised by
                this improvement.
              </div>
              <div class="carousel-sign">
                Liliane Saberin <span>Clearwater FL</span>
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="carousel-item">
              <div class="carousel-text">
                When it comes to reading, many benefits come with it. But one of
                the most important benefits is how reading can change a person's
                inner life permanently. Reading has the power to open up a
                person's mind to new and different ideas, which can then lead to
                a person growing and developing as a person.
              </div>
              <div class="carousel-sign">Chad Jones <span>Parent</span></div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <!--card-->
    </div>
    <!--section-side-->
    <div v-if="!isDevice" class="section-side flex-grow-1">
      <div class="card card-bordered pa-6 pa-lg-8 flex-grow-1 d-none">
        <div class="card-top">
          <div class="card-title2">
            Unlock Reading Potential
          </div>
          <div class="card-text2">
            Experience the remarkable benefits of WholeReader's innovative
            approach to reading
          </div>
        </div>
        <div class="card-bottom">
          <TrialButton color="primary" />
        </div>
      </div>
      <!--card-->
      <div class="card card-small card-bg-pink pa-6 pa-lg-8 flex-grow-1">
        <div class="pre-text">Help us improve our product</div>
        <h2 class="card-title">Make a donation <br />to support our project</h2>
        <div class="card-pic">
          <v-img :src="picDonation" alt="picDonation" />
        </div>
        <div class="card-text">
          We are proud to be a non-profit organization that allows you to learn
          English for free. You can invest in the development of our project as
          a one-time donation or through monthly payments.
        </div>
        <div class="card-bottom">
          <DonationControl
            class="px-8"
            x-large
            color="#000"
            text-color="#fff"
            control-label="Donate now"
          />
          <div class="footer-text mt-2">Immersive Ocean INC.</div>
        </div>
      </div>
      <!--card-->
    </div>
    <!--section-side-->
  </section>
  <!--section-->
</template>

<script>
import { mapGetters } from 'vuex';

import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

import TrialButton from './TrialButton.vue';
import DonationControl from '@/components/controls/DonationControl/DonationControl.vue';

import carouselQuote from '@/components/views/AboutView/ffa/img/ico-quote.svg';
import picDonation from '@/components/views/AboutView/ffa/img/pic-donation.svg';

export default {
  components: {
    BaseSpriteIcon,
    BaseButton,
    TrialButton,
    DonationControl
  },
  data() {
    return {
      picDonation,
      carouselQuote,
      carouseModel: 0
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['isDevice'])
  }
};
</script>

<style lang="less">
@import url('./AboutViewMedia.less');

.about-section {
  &.-section-eight {
    display: flex;
    gap: var(--gap);

    @media @small, @narrow {
      flex-direction: column;
    }

    .pre-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      text-align: center;

      color: #0f172a;
    }

    .card {
      justify-content: space-between;
      overflow: hidden;

      .card-title {
        justify-content: center;
        text-align: center;
      }

      .card-pic {
        margin: 0 auto;
        width: 60%;

        @media @small {
          width: 90%;
        }

        // .v-responsive__content {
        //   max-width: 100%;
        // }
      }

      .card-text {
        margin: 0 auto;
        max-width: 460px;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-align: center;
      }

      .card-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .footer-text {
        font-weight: 500;
        font-size: 10px;
        line-height: 18px;
        letter-spacing: 0.15em;
        text-align: center;
        text-transform: uppercase;
        color: #0f172a;
      }
    }
  }
}
</style>
