<template>
  <section class="about-section -section-one flex-wrap">
    <div class="section-bg">
      <v-img :lazy-src="sectionOneBg" :src="sectionOneBg" alt="sectionOneBg" />
    </div>
    <div class="section-widget">
      <v-img
        :lazy-src="sectionOneWidget"
        :src="sectionOneWidget"
        alt="sectionOneWidget"
      />
    </div>
    <div class="section-side -with-text">
      <div class="section-text">
        <div class="discount-text"><span>100% free forever</span></div>
        <div class="section-title my-4">
          Immersive Reading: The Future of Language Mastery
        </div>
        <div class="section-description">
          Our synchronized read-along system transforms classic literature into
          your gateway to advanced English proficiency.
        </div>
        <div class="section-buttons mt-4">
          <TrialButton color="primary" />
          <BaseButton
            v-if="!isDevice"
            x-large
            elevation="0"
            class="pa-0"
            color="#FAD873"
            @click="openLink(appLinkAppStore)"
            ><BaseSpriteIcon icon-name="ico-soc-apple"
          /></BaseButton>
          <BaseButton
            v-if="!isDevice"
            x-large
            elevation="0"
            class="pa-0"
            color="#FAD873"
            @click="openLink(appLinkPlayStore)"
            ><BaseSpriteIcon icon-name="ico-google-play-fill"
          /></BaseButton>
        </div>
      </div>
    </div>
    <div class="section-side">
      <div class="section-pic">
        <v-img
          :lazy-src="sectionOnePic"
          :src="sectionOnePic"
          alt="sectionOnePic"
        />
      </div>
    </div>
  </section>
  <!--section-->
</template>

<script>
import { mapGetters } from 'vuex';

import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import TrialButton from './TrialButton.vue';

import sectionOneBg from '@/components/views/AboutView/ffa/img/pic-sound-on.webp';
import sectionOneWidget from '@/components/views/AboutView/ffa/img/pic-goal.webp';
import sectionOnePic from '@/components/views/AboutView/ffa/img/pic-immersive-reading.webp';

export default {
  components: {
    BaseSpriteIcon,
    BaseButton,
    TrialButton
  },
  data() {
    const appLinkPlayStore =
      'https://play.google.com/store/apps/details?id=com.firmfoundation.reader&hl=en';
    const appLinkAppStore =
      'https://apps.apple.com/ua/app/wholereader-immersive-reading/id1274106744';
    return {
      sectionOneBg,
      sectionOneWidget,
      sectionOnePic,
      appLinkPlayStore,
      appLinkAppStore
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['isDevice'])
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="less">
@import url('./AboutViewMedia.less');

.about-section {
  &.-section-one {
    display: flex;
    background-color: #f7f6f4;
    background-position: right bottom;
    border-radius: 24px;
    min-height: 600px;
    position: relative;
    overflow: hidden;
    gap: var(--gap);

    @media @normal, @normalPlus, @wide {
      min-height: 550px;
    }

    @media @small, @narrow {
      flex-direction: column;
      min-height: 0;
      gap: 150px;
    }

    @media @narrow {
      gap: 20px;
    }

    @media @small {
      gap: 140px;
    }

    .section-bg {
      width: 480px;
      position: absolute;
      top: 10px;
      right: 230px;

      @media @wide {
        width: 400px;
        top: 40px;
        right: 200px;
      }

      @media @normalPlus {
        width: 380px;
        top: 70px;
        right: 160px;
      }

      @media @normal {
        width: 410px;
        top: 80px;
        right: 100px;
      }

      @media @narrow {
        width: 440px;
        top: auto;
        bottom: 0;
        right: auto;
        left: 50%;
        margin-left: -220px;
      }

      @media @small {
        width: 290px;
        top: auto;
        bottom: 0;
        right: auto;
        left: 50%;
        margin-left: -145px;
      }
    }

    .section-widget {
      width: 200px;
      position: absolute;
      top: 25px;
      left: 50%;
      margin-left: -100px;

      @media @wide {
        width: 150px;
        top: 85px;
        left: auto;
        right: 490px;
        margin-inline-start: 0;
      }

      @media @normalPlus {
        width: 140px;
        top: 145px;
        left: auto;
        right: 460px;
        margin-inline-start: 0;
      }

      @media @normal {
        width: 120px;
        top: 175px;
        left: auto;
        right: 380px;
        margin-inline-start: 0;
      }

      @media @narrow {
        width: 185px;
        top: auto;
        bottom: 240px;
        left: 50%;
        margin-inline-start: -280px;
      }

      @media @small {
        width: 97px;
        top: auto;
        bottom: 210px;
        left: 50%;
        margin-inline-start: -150px;
      }
    }

    .section-side {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.-with-text {
        @media @normal, @normalPlus, @wide {
          min-width: 600px;
        }
      }
    }

    .section-pic {
      width: 300px;
      align-self: flex-end;

      @media @wide {
        width: 240px;
        margin-inline-end: 40px;
      }

      @media @normalPlus {
        width: 210px;
        margin-inline-end: 0;
        margin-top: 90px;
      }

      @media @normal {
        width: 180px;
        margin-inline-end: 0;
        margin-top: 120px;
      }

      @media @narrow {
        width: 220px;
        margin-inline-end: calc(50% - 355px);
        margin-bottom: 90px;
      }

      @media @small {
        width: 130px;
        margin-inline-end: calc(50% - 160px);
        margin-bottom: 20px;
      }
    }

    .section-text {
      padding: 32px;
      max-width: 640px;

      @media @wide {
        padding: 24px;
      }

      @media @narrow, @normal, @normalPlus {
        padding: 24px;
        max-width: 550px;
      }

      @media @small {
        padding: 16px;
        max-width: none;
      }
    }

    .discount-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      @media @small, @narrow {
        font-size: 12px;
        line-height: 16px;
      }

      span {
        background: #fad873;
        border-radius: 4px;
        padding: 4px 8px;
      }
    }

    .section-title {
      font-weight: 700;
      font-size: 56px;
      line-height: 60px;
      letter-spacing: -0.04em;

      @media @narrow {
        font-size: 48px;
        line-height: 48px;
      }

      @media @small {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .section-description {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.02em;

      max-width: 380px;

      @media @small, @narrow {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .section-buttons {
      display: flex;
      gap: var(--gap);
    }

    .v-btn:not(.v-btn--round).v-size--x-large {
      min-width: 48px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
