<template>
  <section class="about-section  -section-nine flex-wrap">
    <div
      class="card text-color-white card-bg-primary card-content-centered card-small pa-10"
    >
      <div class="card-title">
        Available for all platforms
      </div>
      <div class="platforms-list">
        <div class="platform-item">
          <v-img :lazy-src="icoGlobe" :src="icoGlobe" alt="icoGlobe" />
          <div>Web</div>
        </div>
        <div class="platform-item">
          <v-img
            :lazy-src="icoPwa"
            :src="icoPwa"
            alt="icoPwa"
            class="ico-pwa"
          />
        </div>
        <div class="platform-item">
          <v-img :lazy-src="icoWindows" :src="icoWindows" alt="icoWindows" />
          <div>Windows</div>
        </div>
        <div class="platform-item">
          <v-img :lazy-src="icoApple" :src="icoApple" alt="icoApple" />
          <div>macOS</div>
        </div>
        <div class="platform-item">
          <v-img :lazy-src="icoLinux" :src="icoLinux" alt="icoLinux" />
          <div>Linux</div>
        </div>
        <div class="platform-item">
          <v-img :lazy-src="icoAndroid" :src="icoAndroid" alt="icoAndroid" />
          <div>Android</div>
        </div>
        <div class="platform-item">
          <v-img :lazy-src="icoApple" :src="icoApple" alt="icoApple" />
          <div>iOS</div>
        </div>
      </div>
      <div v-if="!isDevice" class="links-block">
        By downloading WholeReader, you accept our <br />
        <a :href="thermOfUseLink" rel="noopener noreferrer" target="_blank"
          >Terms Of Use</a
        >
        and
        <a :href="privacyPolicyLink" rel="noopener noreferrer" target="_blank"
          >Privacy Policy</a
        >
      </div>
    </div>
  </section>
  <!--section-->
</template>

<script>
import { mapGetters } from 'vuex';

import icoAndroid from '@/components/views/AboutView/ffa/img/ico-android.svg';
import icoApple from '@/components/views/AboutView/ffa/img/ico-apple.svg';
import icoGlobe from '@/components/views/AboutView/ffa/img/ico-globe.svg';
import icoLinux from '@/components/views/AboutView/ffa/img/ico-linux.svg';
import icoPwa from '@/components/views/AboutView/ffa/img/ico-pwa.svg';
import icoWindows from '@/components/views/AboutView/ffa/img/ico-windows.svg';
import icoButtonPlay from '@/components/views/AboutView/ffa/img/ico-button-play.svg';

export default {
  data() {
    const thermOfUseLink = this.$store.getters['ContextStore/getTermOfUseLink'];
    const privacyPolicyLink = this.$store.getters[
      'ContextStore/getPrivacyPolicyLink'
    ];
    return {
      icoAndroid,
      icoApple,
      icoGlobe,
      icoLinux,
      icoPwa,
      icoWindows,
      icoButtonPlay,
      thermOfUseLink,
      privacyPolicyLink
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['isPurchaseEnabled', 'isDevice'])
  }
};
</script>
