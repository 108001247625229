<template>
  <section class="about-section -section-ticker">
    <div
      class="card card-small card-row text-color-white card-bg-secondary-accent py-5"
    >
      <div class="ticker">
        100% free forever
        <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
        Unlimited free access to all features with registration only
        <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
        Available for all platforms
        <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
        100% free forever
        <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
        Unlimited free access to all features with registration only
        <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
        Available for all platforms
        <v-img :lazy-src="icoSun" :src="icoSun" alt="icoSun" />
      </div>
    </div>
    <!--card-->
  </section>
  <!--section-->
</template>

<script>
import icoSun from '@/components/views/AboutView/ffa/img/ico-sun.svg';

export default {
  name: 'SectionTicker',
  data() {
    return {
      icoSun
    };
  }
};
</script>

<style lang="less">
.about-section {
  &.-section-ticker {
    .card {
      overflow: hidden;
      border-radius: 16px;
    }
  }

  .ticker {
    --gap: 40px;
    display: flex;
    gap: var(--gap);
    align-items: center;
    position: relative;
    white-space: nowrap;
    margin-left: 0;
    animation: ticker 10s linear infinite;
    font-size: 24px;
    font-weight: 700;

    .v-image {
      width: 32px;
      height: 32px;
    }
  }

  @keyframes ticker {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * (50% + var(--gap) / 2)));
    }
  }
}
</style>
