<template>
  <section class="about-section -section-three flex-wrap">
    <div class="cards-wrapper">
      <div
        v-for="(card, index) in cards"
        :key="index"
        class="card card-bg-gray pa-4 pa-lg-6 flex-grow-1"
      >
        <div class="card-top">
          <div class="card-title">
            <div v-html="card.title" />
            <div class="feature-pic-block">
              <v-img :lazy-src="card.image" :src="card.image" :alt="card.alt" />
            </div>
          </div>
        </div>
        <div class="card-bottom">{{ card.description }}</div>
      </div>
      <!--card-->
    </div>
  </section>
  <!--section-->
</template>

<script>
import featuresPic1 from '@/components/views/AboutView/ffa/img/pic-heart.svg';
import featuresPic2 from '@/components/views/AboutView/ffa/img/pic-key.svg';
import featuresPic3 from '@/components/views/AboutView/ffa/img/pic-books.svg';

export default {
  data() {
    const cards = [
      {
        title: 'Is this really<br/>free?',
        description:
          'Yes, completely free. We are a non-profit organization, so you don’t pay anything to use this app. All features are free for everyone.',
        image: featuresPic1,
        alt: 'featuresPic1'
      },
      {
        title: 'Why should I<br/>sign up?',
        description:
          'Sign up to track your progress, save books, take notes, and read offline. Everything stays in one place for easy use.',
        image: featuresPic2,
        alt: 'featuresPic2'
      },
      {
        title: 'Why read if I already know English?',
        description:
          'Even if you know English, reading helps you find new words, enjoy stories, and learn more. It opens new horizons.',
        image: featuresPic3,
        alt: 'featuresPic3'
      }
    ];
    return {
      featuresPic1,
      featuresPic2,
      featuresPic3,

      cards
    };
  }
};
</script>

<style lang="less">
@import url('./AboutViewMedia.less');

.about-section {
  &.-section-three {
    display: flex;

    @media @small, @narrow {
      flex-direction: column;
    }

    @media @small {
      .section-side {
        flex-direction: column;
      }
    }

    .cards-wrapper {
      display: flex;
      gap: var(--gap);
      flex-grow: 1;

      @media @small {
        flex-direction: column;
      }
    }

    .card {
      min-height: 320px;
      flex: 1 1 0;
      gap: calc(var(--gap) * 7 / 3);

      @media @normal, @narrow, @small {
        min-height: auto;
      }

      .card-title {
        font-size: 32px;
        line-height: 36px;

        @media @normal, @narrow, @small {
          font-size: 20px;
          line-height: 24px;
        }

        > *:first-of-type {
          max-width: 240px;
        }
      }
    }

    .feature-pic-block {
      width: 80px;
      height: 80px;
      display: flex;

      @media @normal, @narrow, @small {
        width: 56px;
        height: 56px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
