<template>
  <div>
    <div
      :class="{ '-user-logged-in': isLoggedIn }"
      dir="auto"
      class="about-content px-4 px-md-6 px-lg-6 py-3"
    >
      <SectionOne />
      <SectionTicker v-if="!isPurchaseEnabled" />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive :publications-count="publicationsCount" />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppFooter from '@/components/views/AppFooter/AppFooter.vue';
import SectionTicker from './SectionTicker.vue';
import SectionOne from './SectionOne.vue';
import SectionTwo from './SectionTwo.vue';
import SectionThree from './SectionThree.vue';
import SectionFour from './SectionFour.vue';
import SectionFive from './SectionFive.vue';
import SectionSix from './SectionSix.vue';
import SectionSeven from './SectionSeven.vue';
import SectionEight from './SectionEight.vue';
import SectionNine from './SectionNine.vue';

export default {
  name: 'AboutViewFfa',
  components: {
    SectionTicker,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive,
    SectionSix,
    SectionSeven,
    SectionEight,
    SectionNine,
    AppFooter
  },
  props: {
    publicationsCount: Object
  },

  computed: {
    ...mapGetters('ContextStore', ['isPurchaseEnabled']),
    isLoggedIn() {
      return this.$store.getters['UserStore/isLoggedIn'];
    }
  }
};
</script>

<style lang="less" src="./AboutViewFfa.less"></style>
