<template>
  <section class="about-section -section-seven flex-wrap">
    <h2 class="section-title">
      <span><BaseSpriteIcon icon-name="ico-book-new"/></span>
      <span>Popular books</span>
    </h2>
    <div class="book-list">
      <div
        v-for="(book, index) in books"
        :key="index"
        class="book-card"
        @click="toBook(book)"
      >
        <div class="book-cover">
          <img :src="book.cover" :alt="book.title" />
        </div>
        <div class="book-details">
          <div class="book-title">{{ book.title }}</div>
          <div class="book-author">{{ book.author }}</div>
          <div class="book-meta">
            <span class="duration">{{ book.duration }}</span>
            <span v-if="book.hasAudio" class="audio-icon">
              <BaseSpriteIcon icon-name="ico-headset-wire" />
            </span>
          </div>
          <div class="book-level">
            <span>Level {{ book.level }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-text">
      Browse through thousands of free books today. Whether you're here to learn
      or just love a good story, we've got you covered.
    </div>
    <div class="bottom-button mt-6">
      <BaseButton
        elevation="0"
        x-large
        nuxt
        color="primary"
        class="library-button px-6"
        @click="openLibrary"
        >Explore library
        <BaseSpriteIcon icon-name="ico-arrow-right-line"></BaseSpriteIcon
      ></BaseButton>
    </div>
  </section>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import publicationUtils from '@/services/utils/publicationUtils';

import cover1 from '@/components/views/AboutView/ffa/img/bookCover/image1.webp';
import cover2 from '@/components/views/AboutView/ffa/img/bookCover/image2.webp';
import cover3 from '@/components/views/AboutView/ffa/img/bookCover/image3.webp';
import cover4 from '@/components/views/AboutView/ffa/img/bookCover/image4.webp';
import cover5 from '@/components/views/AboutView/ffa/img/bookCover/image5.webp';
import cover6 from '@/components/views/AboutView/ffa/img/bookCover/image6.webp';
import cover7 from '@/components/views/AboutView/ffa/img/bookCover/image7.webp';
import cover8 from '@/components/views/AboutView/ffa/img/bookCover/image8.webp';
import cover9 from '@/components/views/AboutView/ffa/img/bookCover/image9.webp';
import cover10 from '@/components/views/AboutView/ffa/img/bookCover/image10.webp';
import cover11 from '@/components/views/AboutView/ffa/img/bookCover/image11.webp';
import cover12 from '@/components/views/AboutView/ffa/img/bookCover/image12.webp';

export default {
  components: {
    BaseButton,
    BaseSpriteIcon
  },
  data() {
    return {
      books: [
        {
          title: 'Three Little Kittens',
          author: 'Katharine Pyle',
          duration: '1:16 h',
          hasAudio: true,
          level: '6.27',
          cover: cover1,
          type: PublicationsTypesEnum.BOOK,
          slug: 'three-little-kittens_katharine-pyle'
        },
        {
          title: 'The Undefeated',
          author: 'Ernest Hemingway',
          duration: '1:16 h',
          hasAudio: true,
          level: '6.27',
          cover: cover2,
          type: PublicationsTypesEnum.BOOK,
          slug: 'undefeated_ernest-hemingway'
        },
        {
          title: 'The Gold Bug',
          author: 'Edgar Allan Poe',
          duration: '1:16 h',
          hasAudio: true,
          level: '6.27',
          cover: cover3,
          type: PublicationsTypesEnum.BOOK,
          slug: 'gold-bug_edgar-allan-poe'
        },
        {
          title: 'Hypnos',
          author: 'H. P. Lovecraft',
          duration: '0:21 h',
          hasAudio: true,
          level: '6.92',
          cover: cover4,
          type: PublicationsTypesEnum.BOOK,
          slug: 'hypnos_h-p-lovecraft'
        },
        {
          title: 'Dracula',
          author: 'Bram Stoker',
          duration: '1:16 h',
          hasAudio: true,
          level: '6.27',
          cover: cover5,
          type: PublicationsTypesEnum.BOOK,
          slug: 'dracula_bram-stoker'
        },
        {
          title: 'Make Westing',
          author: 'Jack London',
          duration: '1:16 h',
          hasAudio: true,
          level: '6.27',
          cover: cover6,
          type: PublicationsTypesEnum.BOOK,
          slug: 'make-westing_jack-london'
        },
        {
          title: "Alice's Adventures in Wonderland",
          author: 'Lewis Carroll',
          duration: '2:00 h',
          hasAudio: true,
          level: '5.0',
          cover: cover7,
          type: PublicationsTypesEnum.BOOK,
          slug: 'alices-adventures-in-wonderland_lewis-carroll'
        },
        {
          title: 'The Kingdom of Cards',
          author: 'Rabindranath Tagore',
          duration: '0:37 h',
          hasAudio: true,
          level: '6.57',
          cover: cover8,
          type: PublicationsTypesEnum.BOOK,
          slug: 'kingdom-of-cards_rabindranath-tagore'
        },
        {
          title: 'The Adventure of the Cheap Flat',
          author: 'Agatha Christie',
          duration: '0:34 h',
          hasAudio: true,
          level: '5.34',
          cover: cover9,
          type: PublicationsTypesEnum.BOOK,
          slug: 'adventure-of-the-cheap-flat_agatha-christie'
        },
        {
          title: 'Memoirs of a Yellow Dog',
          author: 'O. Henry',
          duration: '2:05 h',
          hasAudio: true,
          level: '1.49',
          cover: cover10,
          type: PublicationsTypesEnum.BOOK,
          slug: 'memoirs-of-a-yellow-dog_o-henry'
        },
        {
          title: "A Child's Dream of a Star",
          author: 'Charles Dickens',
          duration: '0:51 h',
          hasAudio: true,
          level: '4.21',
          cover: cover11,
          type: PublicationsTypesEnum.BOOK,
          slug: 'childs-dream-of-a-star_charles-dickens'
        },
        {
          title: 'The Adventure of the Six Napoleons',
          author: 'Arthur Conan Doyle',
          duration: '0:47 h',
          hasAudio: true,
          level: '5.00',
          cover: cover12,
          type: PublicationsTypesEnum.BOOK,
          slug: 'adventure-of-the-six-napoleons_arthur-conan-doyle'
        }
      ]
    };
  },
  methods: {
    openLibrary() {
      this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
    },
    toBook(book) {
      this.$router.push(publicationUtils.getPublicationLink(book));
    }
  }
};
</script>

<style lang="less">
@import url('./AboutViewMedia.less');

.about-section {
  &.-section-seven {
    margin-top: 32px;
    margin-bottom: 32px;

    .section-title {
      font-size: 48px;
      line-height: 48px;
      font-weight: 700;
      letter-spacing: -0.04em;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @media @narrow, @normal, @small {
        font-size: 32px;
        line-height: 36px;
      }

      svg {
        width: 64px;
        height: 64px;

        @media @narrow, @normal {
          width: 56px;
          height: 56px;
        }

        @media @small {
          width: 40px;
          height: 40px;
        }
      }
    }

    .book-list {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1040px;
      margin: 32px auto 16px;

      @media @normal, @narrow {
        max-width: 720px;
      }

      @media @small {
        margin: 24px 0 16px;
        gap: 8px;
      }
    }

    .book-card {
      width: 160px;
      margin-bottom: 16px;
      transition: transform 0.2s ease;
      cursor: pointer;

      &:hover {
        transform: translateY(-4px);
      }

      .book-cover {
        height: 224px;
        width: 160px;
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .book-details {
        margin-top: 8px;

        .book-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }

        .book-author {
          font-size: 14px;
          color: var(--primary-color);
          margin-top: 2px;
          font-weight: 500;
        }

        .book-meta {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #64748b;
          margin-top: 4px;
          font-weight: 500;

          .audio-icon {
            margin-left: 4px;

            svg {
              width: 16px;
              height: 16px;
              fill: #64748b;
            }
          }
        }

        .book-level {
          font-size: 14px;
          color: #64748b;
          margin-top: 2px;
          font-weight: 500;
        }
      }
    }

    .bottom-text {
      max-width: 420px;
      margin: 0 auto;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.02em;
    }

    .bottom-button {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    .library-button {
      > * {
        gap: 8px;
      }
    }
  }
}
</style>
