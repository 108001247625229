<template>
  <section class="about-section -section-five flex-wrap">
    <h2 class="section-title">
      <span><BaseSpriteIcon icon-name="ico-library-outline"/></span>
      <span>Explore 2000+ books<br />in the WholeReader library </span>
    </h2>
    <div class="publications-list">
      <div
        v-for="(item, index) in categoriesWithCounts"
        :key="index"
        v-ripple="{ class: 'primary--text' }"
        class="publications-item"
        @click="navigateToCategory(item)"
      >
        <div>
          <BaseIcon
            class="category-icon"
            :stripe-url="spriteUrl"
            :icon-name="item.icon"
          />
        </div>
        <div>
          <div class="category-title">{{ item.name }}</div>
          <div v-if="item.count > 0" class="books-count">
            <span>{{ item.count }} books</span>
            <BaseSpriteIcon icon-name="ico-arrow-right-line"></BaseSpriteIcon>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-text">
      Choose books from 200+ authors in 10 different categories and 30+ genres
      and feel the magic of reading and listening along
    </div>
    <div class="bottom-button mt-6">
      <BaseButton
        elevation="0"
        x-large
        nuxt
        color="primary"
        class="library-button px-6"
        @click="openLibrary"
        >Explore library
        <BaseSpriteIcon icon-name="ico-arrow-right-line"></BaseSpriteIcon
      ></BaseButton>
    </div>
  </section>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/base/BaseIcon/BaseIcon.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import urlUtils from '@shared/utils/url';
import AppStateEnum from '@/enums/AppStateEnum';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import CATEGORIES from '@/enums/CategoryFilterIconConfig';

import spriteCategories from '@/assets/images/svg-images/icons/categories/sprite-categories.svg';

export default {
  components: {
    BaseButton,
    BaseSpriteIcon,
    BaseIcon
  },
  props: {
    publicationsCount: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      spriteUrl: spriteCategories
    };
  },
  computed: {
    categoriesWithCounts() {
      const publicationsCountLower = Object.keys(this.publicationsCount).reduce(
        (acc, key) => {
          acc[key.toLowerCase()] = this.publicationsCount[key];
          return acc;
        },
        {}
      );

      return CATEGORIES[BrandsEnum.FFA].map(category => {
        return {
          name: category.name,
          icon: category.icon,
          count: publicationsCountLower[category.name.toLowerCase()] || 0
        };
      });
    }
  },
  methods: {
    navigateToCategory(category) {
      const encodedName = urlUtils.encodeStringToPath(category.name);
      this.$router.push({
        name: AppStateEnum.CATEGORY,
        params: { name: encodedName }
      });
    },
    openLibrary() {
      this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
    }
  }
};
</script>

<style lang="less">
@import url('./AboutViewMedia.less');

.about-section {
  &.-section-five {
    margin-top: 32px;
    margin-bottom: 32px;

    .section-title {
      font-size: 48px;
      line-height: 48px;
      font-weight: 700;
      letter-spacing: -0.04em;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @media @narrow, @normal, @small {
        font-size: 32px;
        line-height: 36px;
      }

      svg {
        width: 64px;
        height: 64px;

        @media @narrow, @normal {
          width: 56px;
          height: 56px;
        }

        @media @small {
          width: 40px;
          height: 40px;
        }
      }
    }

    .publications-list {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1040px;
      margin: 32px auto;

      @media @normal, @narrow {
        max-width: 720px;
      }

      @media @small {
        margin: 20px 0;
      }

      .publications-item {
        min-height: 128px;
        background-color: #f7f6f4;
        border-radius: 16px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
        min-width: 194px;
        cursor: pointer;
        transition: all 0.25s ease;
        position: relative;
        overflow: hidden;

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
          background-color: #f0efe9;

          .category-icon {
            transform: scale(1.1);
          }

          .books-count svg {
            transform: translateX(3px);
          }
        }

        &:active {
          transform: translateY(1px) scale(0.98);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
          background-color: #e8e7e2;
        }

        @media @normal, @narrow {
          min-width: 128px;
        }

        @media @small {
          min-width: calc(50% - 8px);
        }

        .category-icon {
          width: 36px;
          height: 36px;
          transition: transform 0.3s ease;
        }

        .category-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.03em;

          &:first-letter {
            text-transform: uppercase;
          }
        }

        .books-count {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: #64748b;
          display: flex;
          gap: 4px;
          align-items: center;

          svg {
            fill: #64748b;
            width: 16px;
            height: 16px;
            transition: transform 0.3s ease;
          }
        }
      }
    }

    .bottom-text {
      max-width: 420px;
      margin: 0 auto;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.02em;
    }

    .bottom-button {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    .library-button {
      > * {
        gap: 8px;
      }
    }
  }
}
</style>
